import Link from 'next/link';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import type { MarketplaceNavItem } from '@/components/Layouts';
import { NavItem, MobileHeader } from '@/components/Layouts';
import { AppSettings } from '@/shared/app-common';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
// import ChangeLanguage from '@/components/Common/Language';
// import UserContent from '../UserContent';

const UserContent = dynamic(() => import('../UserContent'));

interface GlobalHeaderProps {
  // hasGift?: boolean;
  isMarketplaceHome?: boolean;
  navItems?: MarketplaceNavItem[];
  isMobile?: boolean;
}

const GlobalHeader = ({ navItems, isMarketplaceHome, isMobile }: GlobalHeaderProps) => {
  const router = useRouter();
  const { isLogin, onOpenLoginModal, isShowGiftView } = useUserProvider();
  const { t } = useTranslation('common');

  const UnloginHeader = () => {
    return (
      <div className={styles.unLogin}>
        <span onClick={() => onOpenLoginModal(true)}>{t('Join now')}</span>
        {` `}
        <span>{t('for a free trendy hair update and be a social trendsetter!')}</span>
      </div>
    );
  };

  const getNavLeftSection = useMemo(() => {
    // if (!!isMarketplaceHome) {
    return (
      <div className={styles.navTitleContainer}>
        <div className={styles.catchPhrase}>
          <Link href="/">
            <RetroTitle
              text={
                <div style={{ height: '32px' }}>
                  <Image
                    className={styles.logo}
                    src={AppSettings.logoPath}
                    alt={AppSettings.name}
                    loading="lazy"
                    width={32}
                    height={32}
                  />
                  <img
                    src={AppSettings.namePath}
                    alt={AppSettings.name}
                    style={{ height: '32px' }}
                  />
                  <Image
                    src={'/images/layouts/collect-icon.png'}
                    alt="collect"
                    width={32}
                    height={32}
                  />
                </div>
              }
              // textColor="#ff401a"
              fontSize={28}
              showAlert={true}
            />
          </Link>
        </div>
      </div>
    );
    // } else {
    //   return (
    //     <div className={styles.navSearch}>
    //       <Link href="/">
    //         <Image
    //           className={styles.sparkStone}
    //           src="/images/layouts/spark-stone-logo.png"
    //           alt="GPTs"
    //           title="GPTs Store"
    //           width={40}
    //           height={11}
    //         />
    //       </Link>
    //       <UniversalSearch />
    //     </div>
    //   );
    // }
  }, [isMarketplaceHome]);

  const formatMenuData = useCallback(
    (navItems?: MarketplaceNavItem[]): MarketplaceNavItem[] => {
      if (isMobile) {
        return navItems?.reduce(
          (acc: MarketplaceNavItem[], cur: MarketplaceNavItem) => {
            return cur?.dropdownItems ? [...acc, ...cur?.dropdownItems] : [...acc, cur];
          },
          [
            // {
            //   text: 'Home',
            //   path: '/',
            // },
          ],
        );
      }
      return navItems;
    },
    [isMobile],
  );

  const renderNavRightSection = useMemo(() => {
    return formatMenuData(navItems)?.map((item, index) => (
      <NavItem
        item={item}
        key={index}
        className={classNames('', {
          'mobile-left-menu-item': isMobile,
        })}
      />
    ));
  }, [formatMenuData, isMobile, navItems, isShowGiftView]);
  // if (isMobile || router.pathname === '/hair-change') {
  if (isMobile) {
    return <MobileHeader>{renderNavRightSection}</MobileHeader>;
  }
  return (
    <div className={styles.headerContainer}>
      {!isLogin && <UnloginHeader />}
      <div
        className={classNames(styles.globalHeaderWarp)}
      >
        <div className={styles.navSection}>{getNavLeftSection}</div>

        <div className={styles.navSection}>{renderNavRightSection}</div>

        <div className={styles.navSection}>
          {/* <div style={{ marginRight: '42px' }}>
          <ChangeLanguage />
        </div> */}

          <UserContent />
        </div>
      </div>
    </div>
  );
};

export default GlobalHeader;
